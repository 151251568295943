import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

function TopPagesTable({ data }) {
  const { t } = useTranslation();

  let dataArray = data.map((o, i) => {
    return {
      id: i,
      page: o[0],
      pageview: o[1],
      uniquePageview: o[2],
      avgTimeOnpage: o[3],
    };
  });

  return (
    <DataGrid
      hideFooter={true}
      disableSelectionOnClick={true}
      columns={[
        { field: "id", hide: true },
        {
          field: "page",
          headerName: t(`analytics_container.data.page`),
          width: 300,
          type: "string",
          description: t(`analytics_container.data.page_description`),
        },
        {
          field: "pageview",
          headerName: t(`analytics_container.data.pageview`),
          width: 150,
          type: "number",
          description: t(`analytics_container.data.pageview_description`),
        },
        {
          field: "uniquePageview",
          headerName: t(`analytics_container.data.unique_pageview`),
          width: 200,
          type: "number",
          description: t(
            `analytics_container.data.unique_pageview_description`
          ),
        },
        {
          field: "avgTimeOnpage",
          headerName: t(`analytics_container.data.avg_time_on_page`),
          width: 200,
          type: "number",
          valueFormatter: ({ value }) => {
            var date = new Date(0);
            date.setSeconds(Number(value)); // specify value for SECONDS here
            var timeString = date.toISOString().substr(11, 8);

            return timeString;
          },
          description: t(
            `analytics_container.data.avg_time_on_page_description`
          ),
        },
      ]}
      rows={dataArray}
    />
  );
}

export default TopPagesTable;
