import {
  Box,
  Card,
  CardContent,
  Icon,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  scoreCard: {
    height: "100%",
  },
}));

function SummaryItemCard({ title, value, infoText, tooltip }) {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.scoreCard}>
      <CardContent>
        <Box display="flex" flexDirection="column">
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
            align="center"
          >
            {title}{" "}
            <Tooltip title={infoText}>
              <Icon fontSize="inherit">info_outline</Icon>
            </Tooltip>
          </Typography>
          <Tooltip title={tooltip ? tooltip : ""}>
            <Typography variant="h5" component="h2" align="center">
              {value}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SummaryItemCard;
