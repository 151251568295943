export const CHANCE_SELECTED_PERIOD = "CHANCE_SELECTED_PERIOD";

export const SELECT_COMPANY_ID = "SELECT_COMPANY_ID";

export const INVALIDATE_COMPANY_DATA = "INVALIDATE_COMPANY_DATA";
export const REQUEST_COMPANY_DATA = "REQUEST_COMPANY_DATA";
export const RECEIVE_COMPANY_DATA = "RECEIVE_COMPANY_DATA";

export const INVALIDATE_ANALYTICS_DATA = "INVALIDATE_ANALYTICS_DATA";
export const REQUEST_ANALYTICS_DATA = "REQUEST_ANALYTICS_DATA";
export const RECEIVE_ANALYTICS_DATA = "RECEIVE_ANALYTICS_DATA";
