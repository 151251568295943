import { useTheme } from "@material-ui/core";
import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";

function TrafficSourcesChart({ data }) {
  const theme = useTheme();
  const { t } = useTranslation();

  function getToolTipHtmlString(sessions, source) {
    return `<div style="margin: 10px;"><p><b>${sessions}</b> ${t(
      `analytics_container.traffic_sources_panel.chart_tooltip`
    )} "<b>${source}</b>"</p></div>`;
  }

  let dataArray = data.map((o, i) => {
    return [o[0], o[1], Number(o[2]), getToolTipHtmlString(Number(o[2]), o[0])];
  });

  return (
    <Chart
      chartType="Sankey"
      width="100%"
      data={[
        [
          t(`generic.from`),
          t(`generic.to`),
          t(`generic.weight`),
          {
            role: "tooltip",
            type: "string",
            p: { html: true },
          },
        ],

        ...dataArray,
      ]}
      height="200px"
      chartPackages={["corechart", "controls", "charteditor"]}
      options={{
        legend: "none",
        enableInteractivity: false,
        tooltip: {
          trigger: "none",
          isHtml: "true",
        },
        sankey: {
          link: { color: { fill: "#dde7f0" } },
          node: {
            colors: [theme.palette.primary.main],
            nodePadding: 40 / 6, //TODO: CHANGE ME TO LENGTH OF DATA COLS
            label: { color: "#000000de" },
          },
        },
      }}
    />
  );
}

export default TrafficSourcesChart;
