import {
  AppBar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { createTheme, ThemeProvider, useTheme } from "@material-ui/core/styles";
import { Language } from "@material-ui/icons";
import { TabContext, TabList } from "@material-ui/lab";
import axios from "axios";
import dayjs from "dayjs";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import querystring from "querystring";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import CompanyHeader from "./components/CompanyHeader";
import SocialMediaButtonsToolBar from "./components/SocialMediaButtonsToolBar";
import * as types from "./constants/ActionTypes";
import AnalyticsContainer from "./containers/AnalyticsContainer";
import CompanyInfoContainer from "./containers/CompanyInfoContainer";
import fetchAnalyticsDataIfNeeded from "./utilities/fetchAnalyticsDataIfNeeded";
import fetchCompanyDataIfNeeded from "./utilities/fetchCompanyDataIfNeeded";
import generateMuiTheme from "./utilities/generateMuiTheme";
import generateNewSelectedInterval from "./utilities/generateNewSelectedInterval";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    padding: "0px",
    background: theme.palette.background.default,
    border: "0px",
    boxShadow: "none",
  },
}));

const Loader = () => (
  <Box className="App">
    <Box position="fixed" top="50%" left="50%">
      <CircularProgress />
    </Box>
  </Box>
);

function Page() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const muiTheme = useTheme();

  const matches = useMediaQuery(muiTheme.breakpoints.up("sm"));

  const popupState = usePopupState({
    variant: "popover",
    popupId: "mainMenu",
  });

  const theme = createTheme(generateMuiTheme(location.search));

  // local state
  const [showAnalyticsTab, setShowAnalyticsTab] = useState(false);
  const [showNoAccessError, setShowNoAccessError] = useState(false);
  const [showGenericError, setShowGenericError] = useState(false);

  // redux
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const companyDataById = useSelector((state) => state.companyDataById);
  const analyticsDataById = useSelector((state) => state.analyticsDataById);
  const selectedInterval = useSelector((state) => state.selectedInterval);

  let querystringObj = querystring.parse(location.search.replace("?", ""));
  const {
    companyId,
    accountToken,
    analyticsToken,
    lang = "nl",
  } = querystringObj;

  // update selected interval based on query param period
  useEffect(() => {
    let intervalUrl = querystringObj.period;
    if (intervalUrl) {
      let newInterval = generateNewSelectedInterval(intervalUrl);

      dispatch({
        ...newInterval,
        type: types.CHANCE_SELECTED_PERIOD,
      });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (analyticsToken) {
      setShowAnalyticsTab(true);
    }

    if (companyId) {
      dispatch({
        type: types.SELECT_COMPANY_ID,
        companyId: companyId,
      });
    }

    async function fetchCompanyData() {
      if (fetchCompanyDataIfNeeded(companyDataById, companyId)) {
        try {
          dispatch({
            type: types.REQUEST_COMPANY_DATA,
            companyId: companyId,
          });

          const response = await axios.get(
            `https://company-api.datalaag.nl/data/company-data?companyId=${companyId}&startDate=${dayjs(
              selectedInterval.startDate
            ).format("YYYY-MM-DD")}&endDate=${dayjs(
              selectedInterval.endDate
            ).format("YYYY-MM-DD")}`,
            {
              headers: {
                "x-api-key": accountToken,
              },
            }
          );
          dispatch({
            type: types.RECEIVE_COMPANY_DATA,
            companyId: companyId,
            companyInfo: response.data.company,
            analyticsData: response.data.analyticsData,
          });
        } catch (error) {
          if (error.response) {
            if (error.response.status === 403) {
              setShowNoAccessError(true);
            } else {
              setShowGenericError(true);
            }
          } else {
            setShowGenericError(true);
          }
          dispatch({
            type: types.INVALIDATE_ANALYTICS_DATA,
            companyId: companyId,
          });
        }
      }
    }

    async function fetchAnalyticsData() {
      let startDate = dayjs(selectedInterval.startDate).format("YYYY-MM-DD");
      let endDate = dayjs(selectedInterval.endDate).format("YYYY-MM-DD");
      let timeRange = `${startDate}-${endDate}`;

      if (fetchAnalyticsDataIfNeeded(analyticsDataById, companyId, timeRange)) {
        try {
          dispatch({
            type: types.REQUEST_ANALYTICS_DATA,
            companyId: companyId,
          });

          const response = await axios.get(
            `https://company-api.datalaag.nl/data/company-data?companyId=${companyId}&analyticsToken=${analyticsToken}&startDate=${startDate}&endDate=${endDate}`,
            {
              headers: {
                "x-api-key": accountToken,
              },
            }
          );
          dispatch({
            type: types.RECEIVE_ANALYTICS_DATA,
            companyId: companyId,
            timeRange: timeRange,
            analyticsData: response.data.analyticsData,
          });
        } catch (error) {
          if (error.response) {
            if (error.response.status === 403) {
              setShowGenericError(true);
            } else {
              showGenericError(true);
            }
          } else {
            setShowGenericError(true);
          }
          dispatch({
            type: types.INVALIDATE_ANALYTICS_DATA,
            companyId: companyId,
          });
        }
      }
    }

    if (selectedInterval.startDate && companyId) {
      fetchCompanyData();
      if (analyticsToken) {
        fetchAnalyticsData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterval.endDate, selectedInterval.startDate]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const { isFetching, companyInfo } = companyDataById[selectedCompanyId] || {
    isFetching: true,
  };

  if (showNoAccessError === true) {
    return (
      <Dialog open={true}>
        <DialogTitle>{t(`generic.no_access.title`)}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {t(`generic.no_access.description`)}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  if (showGenericError === true) {
    return (
      <Dialog open={true}>
        <DialogTitle>{t(`generic.error.title`)}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {t(`generic.error.description`)}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  if (!companyId) {
    return (
      <Dialog open={true}>
        <DialogTitle>{t(`generic.company_id_not_found.title`)}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {t(`generic.company_id_not_found.description`)}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  if (isFetching === true) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>{`${companyInfo.name} | Datalaag`}</title>
        <meta name="description" content={`${companyInfo.name} | Datalaag`} />
      </Helmet>

      <Container maxWidth="md" disableGutters={!matches}>
        {matches === true && <Box className={classes.toolbar} />}
        <TabContext value={location.pathname}>
          <Card variant="outlined">
            <CompanyHeader />

            <SocialMediaButtonsToolBar />
            {showAnalyticsTab === true && (
              <AppBar position="static">
                <TabList
                  value={location.pathname}
                  onChange={(event, newValue) => {
                    history.push({
                      pathname: newValue,
                      search: location.search,
                    });
                  }}
                  className={classes.tabs}
                  centered
                >
                  <Tab
                    label={t(`company_info_container.tab_title`)}
                    value="/company-info"
                  />
                  <Tab
                    label={t(`analytics_container.tab_title`)}
                    value="/analytics"
                  />
                </TabList>
              </AppBar>
            )}
          </Card>

          <Switch>
            <Route exact path="/">
              <Redirect to="/company-info" />
            </Route>

            <Route path="/company-info">
              <CompanyInfoContainer />
            </Route>
            <Route path="/analytics">
              <AnalyticsContainer showAnalyticsTab={showAnalyticsTab} />
            </Route>
          </Switch>
        </TabContext>
        <Box
          m={1}
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Typography
            style={{}}
            variant="caption"
            align="center"
            color="textSecondary"
          >
            v2.0.1.1
          </Typography>

          <Button {...bindTrigger(popupState)}>
            <Typography
              style={{ textDecoration: "none" }}
              variant="caption"
              align="center"
              color="textSecondary"
            >
              <Icon fontSize="inherit">language</Icon>{" "}
              {i18n.language === "en" ? "English" : "Nederlands"}
              <Icon fontSize="inherit">arrow_drop_down</Icon>
            </Typography>
          </Button>

          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                let newLang = i18n.language === "nl" ? "en" : "nl";
                i18n.changeLanguage(newLang);
                popupState.close();
              }}
            >
              <ListItemIcon>
                <Language fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                {i18n.language === "nl" ? "English" : "Nederlands"}
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}
