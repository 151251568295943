import { LocalizationProvider } from "@material-ui/pickers";
import DayjsUtils from "@material-ui/pickers/adapter/dayjs";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "mapbox-gl/dist/mapbox-gl.css";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
// import i18n (needs to be bundled ;))
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configureStore";

dayjs.extend(relativeTime);
dayjs.extend(duration);

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(localizedFormat);
dayjs.locale("nl");

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={DayjsUtils}>
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          maxSnack={3}
        >
          <Router>
            <App />
          </Router>
        </SnackbarProvider>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
