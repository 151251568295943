import dayjs from "dayjs";

function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

function generatePeriodsList(periods) {
  let periodsList = [];

  var d = new Date();
  d.setHours(0, 0, 0, 0);
  let today = dayjs(convertUTCDateToLocalDate(d));

  let yesterday = today.subtract(1, "day");
  let lastMonth = today.subtract(1, "month");

  let last7Days = today.subtract(7, "day");
  let last14Days = today.subtract(14, "day");
  let last21Days = today.subtract(21, "day");
  let last30Days = today.subtract(30, "day");

  let lastWeek = today.subtract(1, "week");
  let last3Month = today.subtract(3, "month");
  let last12Month = today.subtract(12, "month");
  let lastYearStart = today
    .subtract(1, "year")
    .startOf("month")
    .startOf("year");
  let lastYearEnd = today.subtract(1, "year").endOf("month").endOf("year");

  let tempLastMonthStartDate = lastMonth
    .startOf("month")
    .add(2, "hour")
    .toDate();
  tempLastMonthStartDate.setHours(0, 0, 0, 0);

  let lastMonthStartDate = dayjs(
    convertUTCDateToLocalDate(tempLastMonthStartDate)
  );

  let tempPrevMonthEndDate = lastMonth
    .add(1, "month")
    .startOf("month")

    .subtract(1, "day")
    .toDate();

  tempPrevMonthEndDate.setHours(0, 0, 0, 0);

  let prevMonthEndDate = dayjs(convertUTCDateToLocalDate(tempPrevMonthEndDate));

  let tempLast3MonthStartDate = last3Month.startOf("month").toDate();

  tempLast3MonthStartDate.setHours(0, 0, 0, 0);

  let last3MonthStartDate = dayjs(
    convertUTCDateToLocalDate(tempLast3MonthStartDate)
  );

  let tempLast12MonthStartDate = last12Month.startOf("month").toDate();

  let last12MonthStartDate = dayjs(
    convertUTCDateToLocalDate(tempLast12MonthStartDate)
  );

  let tempLastYearEndDate = lastYearEnd.endOf("month").toDate();
  tempLastYearEndDate.setHours(0, 0, 0, 0);

  let newLastYearEndDate = dayjs(
    convertUTCDateToLocalDate(tempLastYearEndDate)
  );

  for (let i = 0; i < periods.length; i++) {
    let periodName = periods[i];
    switch (periodName) {
      case "LAST_7_DAYS": {
        periodsList.push({
          interval: "day",
          url: "last-7-days",
          startDate: last7Days.valueOf(),
          endDate: yesterday.valueOf(),
        });
        break;
      }

      case "LAST_14_DAYS": {
        periodsList.push({
          interval: "day",
          url: "last-14-days",
          startDate: last14Days.valueOf(),
          endDate: yesterday.valueOf(),
        });
        break;
      }

      case "LAST_21_DAYS": {
        periodsList.push({
          interval: "day",
          url: "last-21-days",
          startDate: last21Days.valueOf(),
          endDate: yesterday.valueOf(),
        });
        break;
      }

      case "LAST_30_DAYS": {
        periodsList.push({
          interval: "day",
          url: "last-30-days",
          startDate: last30Days.valueOf(),
          endDate: yesterday.valueOf(),
        });
        break;
      }

      case "LAST_WEEK": {
        periodsList.push({
          interval: "day",
          url: "last-week",
          startDate: lastWeek.set("day", 1).valueOf(),
          endDate: lastWeek.set("day", 0).add(1, "week").valueOf(),
        });

        break;
      }

      case "LAST_YEAR": {
        periodsList.push({
          interval: "month",
          url: "last-year",
          startDate: lastYearStart.valueOf(),
          endDate: newLastYearEndDate.valueOf(),
        });
        break;
      }

      case "LAST_MONTH": {
        periodsList.push({
          interval: "day",
          url: "last-month",
          startDate: lastMonthStartDate.valueOf(),
          endDate: prevMonthEndDate.valueOf(),
        });
        break;
      }

      case "LAST_3_MONTHS": {
        periodsList.push({
          url: "last-3-month",
          interval: "week",
          startDate: last3MonthStartDate.valueOf(),
          endDate: prevMonthEndDate.valueOf(),
        });
        break;
      }

      case "LAST_12_MONTHS": {
        periodsList.push({
          interval: "month",
          url: "last-12-month",
          startDate: last12MonthStartDate.valueOf(),
          endDate: prevMonthEndDate.valueOf(),
        });
        break;
      }

      default:
        break;
    }
  }

  return periodsList;
}

export default generatePeriodsList;
