import { Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SiHubspot, SiAddthis } from 'react-icons/si';
import { useLocation } from 'react-router';

function AddToCrmButton({}) {
  const location = useLocation();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let querystringObj = querystring.parse(location.search.replace('?', ''));
  const { companyId, accountToken, analyticsToken } = querystringObj;

  const [config, setConfig] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  async function getConfig() {
    setShowLoader(false);
    const response = await axios.get(
      `https://company-api.datalaag.nl/data/crm/config?companyId=${companyId}&analyticsToken=${analyticsToken}`,
      {
        headers: {
          'x-api-key': accountToken,
        },
      }
    );
    const data = response.data;

    setConfig(data);
  }

  // show snackbar after clicking copy button
  useEffect(() => {
    getConfig();
  }, []);

  async function addToCrm() {
    try {
      setShowLoader(true);
      await axios.post(
        `https://company-api.datalaag.nl/data/crm/new`,
        {
          companyId: companyId,
          analyticsToken: analyticsToken,
        },
        {
          headers: {
            'x-api-key': accountToken,
          },
        }
      );

      enqueueSnackbar(t(`generic.successful_added`), {
        variant: 'success',
      });

      setTimeout(function () {
        getConfig();
      }, 3000);

      setShowLoader(false);
    } catch (e) {
      enqueueSnackbar(t(`generic.error.description`), {
        variant: 'error',
      });
      console.log(e);
      setShowLoader(false);
    }
  }

  if (!config) {
    return <CircularProgress size={20} />;
  }

  let icon = null;
  let title = '';

  if (config && config.service === 'HUBSPOT') {
    icon = <SiHubspot />;
    title = 'Add to Hubspot';
  } else if (config && config.service === 'AFAS') {
    icon = <SiAddthis />;
    title = 'Add to AFAS';
  }

  return (
    <>
      {config.isEnabled === true && (
        <Button
          disabled={config.alreadyExists}
          color="secondary"
          onClick={() => {
            addToCrm();
          }}
          startIcon={icon}
          variant="outlined"
        >
          {showLoader === true ? <CircularProgress size={20} /> : <>{title}</>}
        </Button>
      )}
    </>
  );
}

export default AddToCrmButton;
