import Loadable from "@loadable/component";
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  Paper,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import {
  AspectRatio,
  Business,
  GpsFixed,
  LocationOn,
  ZoomOutMap,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl";
import { usePalette } from "react-palette";
import { useSelector } from "react-redux";
import InfoListItem from "./InfoListItem";

const ReactMapGL = Loadable(() => import(`react-map-gl`));

// constants
const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;
const SIZE = 40;

function CompanyLocationMap() {
  const theme = useTheme();
  const { t } = useTranslation();

  // redux
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const companyDataById = useSelector((state) => state.companyDataById);

  const { companyInfo } = companyDataById[selectedCompanyId];
  let logoUrl = `https://logo.clearbit.com/${companyInfo.domain}`;

  const { data, loading, error } = usePalette(logoUrl);

  // locale state
  const [open, setOpen] = useState(false);
  const [zoomArea, setZoomArea] = useState("LOCALE");
  const [onClick, setOnClick] = useState(false);
  const [viewport, setViewport] = useState({
    longitude: Number(companyInfo.location.lon),
    latitude: Number(companyInfo.location.lat),
    zoom: 14,
    bearing: 0,
    pitch: 0,
    width: 972,
    height: 900,
  });

  // change zoom level based on toggle switch
  useEffect(() => {
    let zoom = 14;
    switch (zoomArea) {
      case "LOCALE":
        zoom = 14;
        break;
      case "NATIONAL":
        zoom = 5;
        break;
      default:
        zoom = 14;
    }
    setViewport({ ...viewport, zoom });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomArea]);

  if (!companyInfo.location.lon || !companyInfo.location.lat) {
    return null;
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="company-location-dialog-title"
        aria-describedby="company-location-dialog-description"
      >
        <DialogTitle id="company-location-dialog-title">
          {t(`company_info_container.location_map.dialog_title`)}
        </DialogTitle>

        <CardMedia
          component="img"
          alt={companyInfo.name}
          height="140"
          image={`https://maps.googleapis.com/maps/api/streetview?size=500x500&location=${companyInfo.location.lat},${companyInfo.location.lon}&key=AIzaSyB2sMDr0ciHSFssh2gJ1kJ5gPr_ZzmoTsM`}
          title={companyInfo.name}
        />
        <CardContent>
          <List className="info-list">
            <InfoListItem
              primary={`${companyInfo.location.lat}, ${companyInfo.location.lon}`}
              secondary={t(`company_info_container.data.gps_coordinates`)}
              typeTitle={t(`company_info_container.data.gps_coordinates`)}
              listIcon={<LocationOn />}
              primaryAction={"COPY"}
            />

            <InfoListItem
              primary={"339 m²"}
              secondary={t(`company_info_container.data.bag_surface`)}
              typeTitle={t(`company_info_container.data.bag_surface`)}
              listIcon={<AspectRatio />}
              primaryAction={"COPY"}
            />

            <InfoListItem
              primary={"Kantoorfunctie"}
              secondary={t(`company_info_container.data.bag_purpose_of_use`)}
              typeTitle={t(`company_info_container.data.bag_purpose_of_use`)}
              listIcon={<Business />}
              primaryAction={"COPY"}
            />
          </List>
        </CardContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            {t(`generic.close_window`)}
          </Button>
        </DialogActions>
      </Dialog>
      <ReactMapGL
        {...viewport}
        getCursor={() => `auto`}
        height={200}
        mapboxApiAccessToken={`pk.eyJ1IjoiZ2xhZGlvciIsImEiOiJjazQ5eGIyb3gwOWI0M21xeGhqZWU3ZHF0In0.d_vs2YwL9HJq2fxLkwgLhA`}
        mapStyle={`mapbox://styles/gladior/ck49ycb3v0wbf1dntjq53jv03`}
        onClick={() => onClick && setOnClick(false)}
        width={`100%`}
      >
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box m={2}>
            <Box display="flex" flexDirection="column">
              <Box>
                <Paper>
                  <ToggleButtonGroup
                    size="small"
                    value={zoomArea}
                    orientation="vertical"
                    exclusive
                    onChange={(event, newAlignment) => {
                      setZoomArea(newAlignment);
                    }}
                  >
                    <ToggleButton value="LOCALE">
                      <Tooltip
                        title={t(`company_info_container.location_map.zoom_in`)}
                      >
                        <GpsFixed fontSize="small" />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton value="NATIONAL">
                      <Tooltip
                        title={t(
                          `company_info_container.location_map.zoom_out`
                        )}
                      >
                        <ZoomOutMap fontSize="small" />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>

        <Marker
          latitude={Number(companyInfo.location.lat)}
          longitude={Number(companyInfo.location.lon)}
        >
          <Tooltip
            title={t(`company_info_container.location_map.open_dialog_tooltip`)}
            arrow
          >
            <svg
              height={SIZE}
              onClick={() => {
                setOpen(true);
              }}
              id="map-marker"
              viewBox="0 0 24 24"
              style={{
                display: "block",
                cursor: "pointer",
                fill: error ? theme.palette.secondary.main : data.darkMuted,
                stroke: "none",
                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
              }}
            >
              <path d={ICON} />
              {!error && loading === false && (
                <svg
                  x="50%"
                  y="50%"
                  width="14px"
                  height="14px"
                  overflow="visible"
                >
                  <foreignObject x="-7px" y="-8px" width="14px" height="14px">
                    <img
                      width="14px"
                      height="14px"
                      alt=""
                      src={logoUrl}
                      style={{ borderRadius: "50%", position: "absolute" }}
                    />
                  </foreignObject>
                </svg>
              )}
            </svg>
          </Tooltip>
        </Marker>
      </ReactMapGL>
    </>
  );
}

export default CompanyLocationMap;
