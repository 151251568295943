import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

function PageviewTimelineTable({ data }) {
  const { t } = useTranslation();

  let dataArray = data.map((o, i) => {
    return {
      id: i,
      dateTime: o[1],
      deviceCategory: "",
      page: o[0],
      pageview: o[2],
      avgTimeOnpage: o[3],
    };
  });

  return (
    <DataGrid
      hideFooter={true}
      disableSelectionOnClick={true}
      columns={[
        { field: "id", hide: true },
        {
          field: "page",
          headerName: t(`analytics_container.data.page`),
          width: 300,
          type: "string",
          description: t(`analytics_container.data.page_description`),
        },

        {
          field: "avgTimeOnpage",
          headerName: t(`analytics_container.data.avg_time_on_page`),
          width: 200,
          type: "number",
          valueFormatter: ({ value }) => {
            let date = new Date(0);
            date.setSeconds(Number(value));
            const timeString = date.toISOString().substr(11, 8);
            return timeString;
          },
          description: t(
            `analytics_container.data.avg_time_on_page_description`
          ),
        },
        {
          field: "pageview",
          headerName: t(`analytics_container.data.pageview`),
          width: 150,
          type: "number",
          description: t(`analytics_container.data.pageview_description`),
        },
        {
          field: "dateTime",
          headerName: t(`analytics_container.data.date_time`),
          width: 170,
          type: "number",
          valueFormatter: ({ value }) => {
            return dayjs(value, "YYYYMMDDhhmm").format("lll");
          },
          description: t(`analytics_container.data.date_time_description`),
        },
      ]}
      rows={dataArray}
    />
  );
}

export default PageviewTimelineTable;
