import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { MdPublic } from "react-icons/md";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  socialIcons: {
    marginRight: "10px",
  },
  socialButton: {
    marginRight: "10px",
    background: theme.palette.background.paper,
  },
}));

function SocialMediaButton({ title, type, url }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  let icon;

  // eslint-disable-next-line default-case
  switch (type) {
    case "WEBSITE":
      icon = <MdPublic className={classes.socialIcons} />;
      break;

    case "FACEBOOK":
      icon = <FaFacebook className={classes.socialIcons} />;
      break;

    case "TWITTER":
      icon = <FaTwitter className={classes.socialIcons} />;
      break;

    case "LINKEDIN":
      icon = <FaLinkedin className={classes.socialIcons} />;
      break;

    case "INSTAGRAM":
      icon = <FaInstagram className={classes.socialIcons} />;
      break;
    case "YOUTUBE":
      icon = <FaYoutube className={classes.socialIcons} />;
      break;
    case "PINTEREST":
      icon = <FaPinterest className={classes.socialIcons} />;
      break;
  }

  if (!url) {
    return null;
  }

  if (matches === false) {
    return (
      <Tooltip
        arrow
        title={t("generic.open_action_sentence", {
          open: t(`generic.open_action`),
          value: title,
        })}
      >
        <IconButton href={url}>{icon}</IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      arrow
      title={t("generic.open_action_sentence", {
        open: t(`generic.open_action`),
        value: title,
      })}
    >
      <Button
        variant="outlined"
        href={url}
        color="default"
        size="small"
        className={classes.socialButton}
      >
        <>{icon}</>
        {matches === true && <>{title}</>}
      </Button>
    </Tooltip>
  );
}

function SocialMediaButtonsToolBar() {
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const companyDataById = useSelector((state) => state.companyDataById);

  const { companyInfo } = companyDataById[selectedCompanyId];

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={"center"}
      mx={2}
      mb={2}
    >
      <Box>
        <SocialMediaButton
          title="Website"
          type="WEBSITE"
          url={companyInfo.social_media.website}
        />
      </Box>

      <Box>
        <SocialMediaButton
          title="Facebook"
          type="FACEBOOK"
          url={companyInfo.social_media.facebook}
        />
      </Box>
      <Box>
        <SocialMediaButton
          title="Twitter"
          type="TWITTER"
          url={companyInfo.social_media.twitter}
        />
      </Box>
      <Box>
        <SocialMediaButton
          title="LinkedIn"
          type="LINKEDIN"
          url={companyInfo.social_media.linkedin}
        />
      </Box>
      <Box>
        <SocialMediaButton
          title="Instagram"
          type="INSTAGRAM"
          url={companyInfo.social_media.instagram}
        />
      </Box>
      <Box>
        <SocialMediaButton
          title="YouTube"
          type="YOUTUBE"
          url={companyInfo.social_media.youtube}
        />
      </Box>
      <Box>
        <SocialMediaButton
          title="Pinterest"
          type="PINTEREST"
          url={companyInfo.social_media.pinterest}
        />
      </Box>
    </Box>
  );
}

export default SocialMediaButtonsToolBar;
