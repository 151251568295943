import { Button, Dialog, DialogActions, TextField } from "@material-ui/core";
import {
  DateRangeDelimiter,
  StaticDateRangePicker,
} from "@material-ui/pickers";
import querystring from "querystring";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

function DateRangeDialog({ startDate, endDate, open, setOpen }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // locale state
  const [value, setValue] = useState([startDate, endDate]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <StaticDateRangePicker
        displayStaticWrapperAs="mobile"
        value={value}
        toolbarTitle={t(`analytics_container.date_range_picker.toolbar_title`)}
        maxDate={new Date()}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} />
            <DateRangeDelimiter>
              {" "}
              {t(`analytics_container.date_range_picker.to`)}{" "}
            </DateRangeDelimiter>
            <TextField {...endProps} />
          </>
        )}
      />

      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
        >
          {t(`generic.cancel`)}
        </Button>
        <Button
          onClick={() => {
            history.push({
              pathname: location.pathname,
              search: `?${querystring.stringify({
                ...querystring.parse(location.search.replace("?", "")),
                period: `${value[0].format("YYYYMMDD")}-${value[1].format(
                  "YYYYMMDD"
                )}`,
              })}`,
            });
            setOpen(false);
          }}
          variant="contained"
          color="primary"
        >
          {t(`generic.apply`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateRangeDialog;
