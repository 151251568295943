const shouldFetchCompanyData = (companyDataById, companyId) => {
  const companyData = companyDataById[companyId];
  if (!companyData) {
    return true;
  }
  if (companyData.isFetching) {
    return false;
  }
  return companyData.didInvalidate;
};

function fetchCompanyDataIfNeeded(companyDataById, companyId) {
  if (shouldFetchCompanyData(companyDataById, companyId)) {
    return true;
  } else {
    return false;
  }
}

export default fetchCompanyDataIfNeeded;
