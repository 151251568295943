import { Accordion as MuiAccordion, AccordionDetails, Box, List, makeStyles, Typography, withStyles } from '@material-ui/core';
import { Business, ContactPhone, Flag, LocationOn, Mail, Phone, Public, Error } from '@material-ui/icons';
// import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AccordionRow from '../components/AccordionRow';
import CompanyLocationMap from '../components/CompanyLocationMap';
import InfoListItem from '../components/InfoListItem';

const Accordion = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  },
  expanded: {},
}))(MuiAccordion);

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.primary.main,
  },
  firstAccordion: {
    marginTop: '0px',
  },
}));

function CompanyInfoContainer() {
  const { t } = useTranslation();
  const classes = useStyles();

  // local state
  const [expanded, setExpanded] = useState('panel1');

  // redux
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const companyDataById = useSelector((state) => state.companyDataById);

  const { companyInfo } = companyDataById[selectedCompanyId];

  return (
    <>
      <CompanyLocationMap />
      <Accordion
        expanded={expanded === 'panel1'}
        className={classes.firstAccordion}
        style={{ marginTop: '0px' }} // inline, somehow useStyles doesn't work here
        onChange={(event, expanded) => {
          setExpanded(expanded === true ? 'panel1' : false);
        }}
      >
        <AccordionRow
          expanded={expanded}
          panelId={'panel1'}
          title={t(`company_info_container.contact_details_panel.title`)}
          subheader={t(`company_info_container.contact_details_panel.subheader`)}
          icon={<ContactPhone />}
        />

        <AccordionDetails>
          <Box width="100%">
            <List className="info-list">
              <InfoListItem
                primary={`${companyInfo.address}, ${companyInfo.postcode} ${companyInfo.city}`}
                typeTitle={t(`company_info_container.data.address`)}
                openTitle={t(`company_info_container.data.address_open`)}
                openLink={`https://www.google.nl/maps/?q=${encodeURIComponent(
                  companyInfo.name + ' ' + `${companyInfo.address}, ${companyInfo.postcode} ${companyInfo.city}`
                )}`}
                listIcon={<LocationOn />}
                primaryAction={'COPY'}
              />
              <InfoListItem
                primary={companyInfo.country}
                typeTitle={t(`company_info_container.data.land`)}
                listIcon={<Flag />}
                primaryAction={'COMPARE'}
              />
              <InfoListItem
                primary={companyInfo.domain}
                typeTitle={t(`company_info_container.data.website`)}
                openTitle={t(`company_info_container.data.website_open`)}
                openLink={companyInfo.site}
                listIcon={<Public />}
                primaryAction={'OPEN'}
              />
              <InfoListItem
                primary={
                  companyInfo.id === '404785910000' ? (
                    <Typography style={{ color: '#ed1e36' }} variant={'inherit'}>
                      {companyInfo.phone_national}
                    </Typography>
                  ) : (
                    <>{companyInfo.phone_national}</>
                  )
                }
                typeTitle={t(`company_info_container.data.phone_number`)}
                listIcon={companyInfo.id === '404785910000' ? <Error color="secondary" /> : <Phone />}
                openLink={`tel:${companyInfo.phone_international}`}
                primaryAction={'OPEN'}
              />
              <InfoListItem
                primary={companyInfo.email}
                typeTitle={t(`company_info_container.data.email`)}
                listIcon={<Mail />}
                openLink={`mailto:${companyInfo.email}`}
                primaryAction={'OPEN'}
              />
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel2'}
        onChange={(event, expanded) => {
          setExpanded(expanded === true ? 'panel2' : false);
        }}
      >
        <AccordionRow
          expanded={expanded}
          panelId={'panel2'}
          title={t(`company_info_container.company_details_panel.title`)}
          subheader={t(`company_info_container.company_details_panel.subheader`)}
          icon={<Business />}
        />

        <AccordionDetails>
          <Box width="100%">
            <List className="info-list">
              {/* <InfoListItem
                primary={`1 ${t(
                  `company_info_container.data.number_of_branches_unit_single`
                )}`}
                secondary={t(`company_info_container.data.number_of_branches`)}
                typeTitle={t(`company_info_container.data.number_of_branches`)}
              /> */}

              <InfoListItem
                primary={companyInfo.orgtype}
                secondary={t(`company_info_container.data.legal_form`)}
                typeTitle={t(`company_info_container.data.legal_form`)}
              />

              <InfoListItem
                primary={companyInfo.industry}
                secondary={t(`company_info_container.data.standard_industrial_classification`)}
                typeTitle={t(`company_info_container.data.standard_industrial_classification_short`)}
              />
              <InfoListItem
                primary={companyInfo.coc}
                secondary={t(`company_info_container.data.chamber_of_commerce_number`)}
                typeTitle={t(`company_info_container.data.chamber_of_commerce_number_short`)}
              />

              {companyInfo.employees.range > 0 && (
                <InfoListItem
                  primary={`0 - ${companyInfo.employees.range} ${t(`company_info_container.data.number_of_employees_unit`)}`}
                  secondary={t(`company_info_container.data.number_of_employees`)}
                  sourceText={t(`company_info_container.data.number_of_employees_source_text`)}
                  typeTitle={t(`company_info_container.data.number_of_employees`)}
                />
              )}

              <InfoListItem
                primary={companyInfo.iban.iban}
                secondary={t(`company_info_container.data.iban_bank_account`)}
                sourceText={`${t(`company_info_container.data.iban_bank_account_source_text`)} ${companyInfo.iban.domain}`}
                typeTitle={t(`company_info_container.data.iban_bank_account_short`)}
              />
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default CompanyInfoContainer;
