import { useTheme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import dayjs from "dayjs";
import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";

function SessionChart({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();

  function getToolTipHtmlString(sessions, source) {
    return `<div style="margin: 10px;"><p><b>${sessions}</b> ${t(
      `analytics_container.traffic_sources_panel.chart_tooltip`
    )} "<b>${source}</b>"</p></div>`;
  }

  let formatedData = data.map((o) => {
    let day = dayjs(o[0], "YYYYMMDD");
    return [day.toDate(), Number(o[1])];
  });

  return (
    <Chart
      width={"100%"}
      height={"200px"}
      chartType="Bar"
      loader={<div>{t(`generic.loading_chart`)}</div>}
      data={[
        [{ type: "date", label: "" }, t(`analytics_container.data.sessions`)],
        ...formatedData,
      ]}
      options={{
        legend: { position: "none" },
        chartArea: {
          // leave room for y-axis labels
          width: "94%",
        },
        tooltip: {
          trigger: "none",
          isHtml: "true",
        },
        width: "100%",
        colors: [theme.palette.primary.main],

        hAxis: {
          gridlines: {
            color: "transparent",
          },
          format: "MMM dd",
          textStyle: { color: theme.palette.grey[700] },
        },
        vAxis: {
          minValue: 0,

          gridlines: {
            color: fade(theme.palette.action.disabled, 0.12),
          },
        },
        pointSize: 5,
      }}
    />
  );
}

export default SessionChart;
