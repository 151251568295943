import {
  Accordion as MuiAccordion,
  AccordionDetails,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  withStyles,
} from "@material-ui/core";
import { Timeline, Traffic, ViewList } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import querystring from "querystring";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import truncate from "truncate";
import AccordionRow from "../components/AccordionRow";
import DateRangeSelector from "../components/DateRangeSelector";
import DevicePieChart from "../components/DevicePieChart";
import PageviewTimelineTable from "../components/PageviewTimelineTable";
import SessionChart from "../components/SessionChart";
import SummaryItemCard from "../components/SummaryItemCard";
import TopPagesTable from "../components/TopPagesTable";
import TrafficSourcesChart from "../components/TrafficSourcesChart";

function formatSeconds(secondsString) {
  const ta = dayjs.duration(Number(secondsString), "seconds");

  return (
    dayjs.utc(ta.asMilliseconds()).format("m") +
    "m " +
    dayjs.utc(ta.asMilliseconds()).format("ss") +
    "s"
  );
}

const Accordion = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  },
  expanded: {},
}))(MuiAccordion);

function AnalyticsContainer({ showAnalyticsTab }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // local state
  const [expanded, setExpanded] = useState("panel1");
  const [loadDateRange, setLoadDateRange] = useState(null);

  // redux
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const analyticsDataById = useSelector((state) => state.analyticsDataById);
  const selectedInterval = useSelector((state) => state.selectedInterval);

  const { analyticsData, isFetching } = analyticsDataById[selectedCompanyId];

  let querystringObj = querystring.parse(location.search.replace("?", ""));
  const { companyId, accountToken, analyticsToken } = querystringObj;

  // update selected interval based on query param period
  useEffect(() => {
    if (isFetching === true && analyticsData !== undefined) {
      enqueueSnackbar(t(`generic.loading_data`));
    } else {
      closeSnackbar();
    }
  }, [analyticsData, closeSnackbar, enqueueSnackbar, isFetching, t]);

  // if no analytics data, redirect to the company info tab
  if (showAnalyticsTab === false) {
    return (
      <Redirect
        to={{
          pathname: "/company-info",
          search: `?${querystring.stringify({
            ...querystringObj,
          })}`,
        }}
      />
    );
  }

  // redirect if no period has found
  if (!querystringObj.period) {
    return (
      <Redirect
        to={{
          pathname: location.pathname,
          search: `?${querystring.stringify({
            ...querystringObj,
            period: "last-30-days",
          })}`,
        }}
      />
    );
  }

  if (isFetching === true && !analyticsData) {
    return (
      <Paper>
        <Box
          height="600px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  if (!analyticsData) {
    return null;
  }

  async function smartDateRange() {
    let startDate = dayjs(selectedInterval.startDate).format("YYYY-MM-DD");
    let endDate = dayjs(selectedInterval.endDate).format("YYYY-MM-DD");
    setLoadDateRange(true);
    const response = await axios.get(
      `https://company-api.datalaag.nl/data/date-range?companyId=${companyId}&analyticsToken=${analyticsToken}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          "x-api-key": accountToken,
        },
      }
    );

    setLoadDateRange(false);
    let rdata = response.data;

    console.log(rdata.period);

    history.push({
      pathname: location.pathname,
      search: `?${querystring.stringify({
        ...querystring.parse(location.search.replace("?", "")),
        period: rdata.period,
      })}`,
    });

    console.log({ response });
  }

  return (
    <Box>
      <Box py={2} display="flex" flexDirection="row">
        <Grid container spacing={2} alignItems="stretch" justify="space-around">
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box mr={2} width="100%">
                    {Number(analyticsData.summary.sessions) === 0 && (
                      <Alert
                        style={{ fontSize: "12px" }}
                        severity="info"
                        action={
                          <Button
                            onClick={smartDateRange}
                            color="inherit"
                            variant="outlined"
                            size="small"
                          >
                            {t(`analytics_container.change_period`)}
                          </Button>
                        }
                      >
                        {t(`analytics_container.no_data`)}
                      </Alert>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <DateRangeSelector
                      loadDateRange={loadDateRange}
                      smartDateRange={smartDateRange}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            style={{ opacity: isFetching === true ? 0.5 : 1 }}
            item
            xs={12}
            sm={4}
          >
            <SummaryItemCard
              title={t(`analytics_container.data.sessions`)}
              value={analyticsData.summary.sessions}
              infoText={t(`analytics_container.data.sessions_info_text`)}
            />
          </Grid>
          <Grid
            style={{ opacity: isFetching === true ? 0.5 : 1 }}
            item
            xs={12}
            sm={4}
          >
            <SummaryItemCard
              title={t(`analytics_container.data.average_session_duration`)}
              value={formatSeconds(analyticsData.summary.avgSessionDuration)}
              infoText={t(
                `analytics_container.data.average_session_duration_info_text`
              )}
            />
          </Grid>
          <Grid
            style={{ opacity: isFetching === true ? 0.5 : 1 }}
            item
            xs={12}
            sm={4}
          >
            <SummaryItemCard
              title={t(`analytics_container.data.source_medium`)}
              tooltip={analyticsData.summary.sourceMedium}
              value={`${truncate(
                analyticsData.summary.sourceMedium.split("/")[0],
                10
              )} / ${truncate(
                analyticsData.summary.sourceMedium.split("/")[1],
                10
              )}`}
              infoText={t(`analytics_container.data.source_medium_info_text`)}
            />
          </Grid>
        </Grid>
      </Box>

      <Accordion
        expanded={expanded === "panel1"}
        style={{ marginTop: "0px", opacity: isFetching === true ? 0.5 : 1 }}
        onChange={(event, expanded) => {
          setExpanded(expanded === true ? "panel1" : false);
        }}
      >
        <AccordionRow
          expanded={expanded}
          panelId={"panel1"}
          title={t(`analytics_container.sessions_progression_panel.title`)}
          subheader={t(
            `analytics_container.sessions_progression_panel.subheader`
          )}
          icon={<Timeline />}
        />

        <AccordionDetails>
          <Box width="100%">
            <SessionChart data={analyticsData.sessionsByDate} />
            <Box mt={4} width="100%" height="350px">
              <PageviewTimelineTable
                data={analyticsData.pagesViewedByDateTime}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        style={{ opacity: isFetching === true ? 0.5 : 1 }}
        paper={<Paper variant="outlined" />}
        onChange={(event, expanded) => {
          setExpanded(expanded === true ? "panel3" : false);
        }}
      >
        <AccordionRow
          expanded={expanded}
          panelId={"panel3"}
          title={t(`analytics_container.traffic_sources_panel.title`)}
          subheader={t(`analytics_container.traffic_sources_panel.subheader`)}
          icon={<Traffic />}
        />

        <AccordionDetails>
          <Box p={2} width="100%">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <TrafficSourcesChart data={analyticsData.topTrafficSources} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DevicePieChart data={analyticsData.topDevices} />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        style={{ opacity: isFetching === true ? 0.5 : 1 }}
        onChange={(event, expanded) => {
          setExpanded(expanded === true ? "panel2" : false);
        }}
      >
        <AccordionRow
          expanded={expanded}
          panelId={"panel2"}
          title={t(`analytics_container.top_most_visited_pages_panel.title`)}
          subheader={t(
            `analytics_container.top_most_visited_pages_panel.subheader`
          )}
          icon={<ViewList />}
        />

        <AccordionDetails>
          <Box width="100%">
            <Box height="350px" width="100%">
              <TopPagesTable data={analyticsData.topPages} />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AnalyticsContainer;
