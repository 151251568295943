import {
  AccordionSummary,
  Avatar,
  CardHeader,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Business, ExpandMore } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.primary.main,
  },
  scoreCard: {
    height: "100%",
  },
}));

function AccordionRow({
  expanded,
  panelId,
  title,
  subheader,
  icon = <Business />,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="right"
      title={t("generic.panel_sentence", {
        action: expanded === panelId ? t(`generic.close`) : t(`generic.open`),
        value: title,
      })}
      arrow
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <CardHeader
          avatar={<Avatar className={classes.avatar}>{icon}</Avatar>}
          title={title}
          subheader={subheader}
        />
      </AccordionSummary>
    </Tooltip>
  );
}

export default AccordionRow;
