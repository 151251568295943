import querystring from "querystring";

function componentFromStr(numStr, percent) {
  var num = Math.max(0, parseInt(numStr, 10));
  return percent
    ? Math.floor((255 * Math.min(100, num)) / 100)
    : Math.min(255, num);
}

function rgbToHex(rgb) {
  var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/;
  var result,
    r,
    g,
    b,
    hex = "";
  if ((result = rgbRegex.exec(rgb))) {
    r = componentFromStr(result[1], result[2]);
    g = componentFromStr(result[3], result[4]);
    b = componentFromStr(result[5], result[6]);

    hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  return hex;
}

function generateMuiTheme(search) {
  let querystringObj = querystring.parse(search.replace("?", ""));

  let primaryMain = querystringObj.colors
    ? `#${querystringObj.colors.split("-")[0]}`
    : "#303133";

  let secMain = querystringObj.colors
    ? `#${querystringObj.colors.split("-")[1]}`
    : "#e0257b";

  if (querystringObj.colors && querystringObj.colors.indexOf("rgb") > -1) {
    primaryMain = rgbToHex(querystringObj.colors.split("-")[0]);

    secMain = rgbToHex(querystringObj.colors.split("-")[1]);
  }

  return {
    palette: {
      primary: {
        main: primaryMain,
      },
      secondary: {
        main: secMain,
      },
    },
  };
}

export default generateMuiTheme;
