import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const selectedInterval = (state = {}, action) => {
  switch (action.type) {
    case types.CHANCE_SELECTED_PERIOD:
      return {
        ...state,
        interval: action.interval,
        startDate: action.startDate,
        endDate: action.endDate,
        url: action.url,
      };
    default:
      return state;
  }
};

const selectedCompanyId = (state = "", action) => {
  switch (action.type) {
    case types.SELECT_COMPANY_ID:
      return action.companyId;
    default:
      return state;
  }
};

const companyData = (
  state = {
    isFetching: false,
    didInvalidate: false,
    companyInfo: undefined,
    analyticsData: undefined,
  },
  action
) => {
  switch (action.type) {
    case types.INVALIDATE_COMPANY_DATA:
      return {
        ...state,
        didInvalidate: true,
      };
    case types.REQUEST_COMPANY_DATA:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.RECEIVE_COMPANY_DATA:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        companyInfo: action.companyInfo,
        analyticsData: action.analyticsData,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const analyticsData = (
  state = {
    isFetching: false,
    didInvalidate: false,
    timeRange: "",
    analyticsData: undefined,
  },
  action
) => {
  switch (action.type) {
    case types.INVALIDATE_ANALYTICS_DATA:
      return {
        ...state,
        didInvalidate: true,
      };
    case types.REQUEST_ANALYTICS_DATA:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case types.RECEIVE_ANALYTICS_DATA:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        timeRange: action.timeRange,
        analyticsData: action.analyticsData,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

const companyDataById = (state = {}, action) => {
  switch (action.type) {
    case types.INVALIDATE_COMPANY_DATA:
    case types.RECEIVE_COMPANY_DATA:
    case types.REQUEST_COMPANY_DATA:
      return {
        ...state,
        [action.companyId]: companyData(state[action.companyId], action),
      };
    default:
      return state;
  }
};

const analyticsDataById = (state = {}, action) => {
  switch (action.type) {
    case types.INVALIDATE_ANALYTICS_DATA:
    case types.RECEIVE_ANALYTICS_DATA:
    case types.REQUEST_ANALYTICS_DATA:
      return {
        ...state,
        [action.companyId]: analyticsData(state[action.companyId], action),
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  selectedCompanyId,
  analyticsDataById,
  companyDataById,
  selectedInterval,
});

export default rootReducer;
