import { useTheme } from "@material-ui/core";
import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";

function DevicePieChart({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();

  let dataArray = data.map((o, i) => {
    return [t(`analytics_container.data.${o[0]}`), Number(o[1])];
  });

  return (
    <Chart
      width="100%"
      chartType="PieChart"
      loader={<div>{t(`generic.loading_chart`)}</div>}
      data={[
        [
          t(`analytics_container.data.device`),
          t(`analytics_container.data.session`),
        ],
        ...dataArray,
      ]}
      options={{
        legend: "bottom",
        chartArea: {
          // leave room for y-axis labels
          width: "94%",
        },
        slices: {
          0: { color: theme.palette.primary.main },
          1: { color: theme.palette.secondary.main },
          2: { color: theme.palette.primary.dark },
          3: { color: theme.palette.primary.light },
        },
      }}
    />
  );
}

export default DevicePieChart;
