import {
  Button,
  CircularProgress,
  makeStyles,
  Menu,
  MenuItem,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import dayjs from "dayjs";
import querystring from "querystring";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import generatePeriodsList from "../utilities/generatePeriodsList";
import DateRangeDialog from "./DateRangeDialog";

let periodList = generatePeriodsList([
  "LAST_7_DAYS",
  "LAST_14_DAYS",
  "LAST_30_DAYS",
  "LAST_WEEK",
  "LAST_MONTH",
  "LAST_3_MONTHS",
  "LAST_12_MONTHS",
  "LAST_YEAR",
]);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles({
  customWidth: {
    width: "250px",
  },
  customButton: {
    width: "250px",
  },
});

function DateRangeSelector({ smartDateRange, loadDateRange }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  // react-router
  const history = useHistory();
  const location = useLocation();

  // locale state
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCustomDateRange, setOpenCustomDateRange] = useState(null);

  // redux
  const selectedInterval = useSelector((state) => state.selectedInterval);

  return (
    <>
      <DateRangeDialog
        open={openCustomDateRange}
        setOpen={setOpenCustomDateRange}
        startDate={dayjs(selectedInterval.startDate).add(1, "day")} // add 1 day because of timezones, mui picker is seems to be UTC time
        endDate={dayjs(selectedInterval.endDate).add(1, "day")}
      />
      <Button
        variant="outlined"
        className={classes.customButton}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {loadDateRange === true ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {dayjs(selectedInterval.startDate)
              .add(3, "hour")
              .format("D MMM YYYY")}{" "}
            -{" "}
            {dayjs(selectedInterval.endDate)
              .add(3, "hour")
              .format("D MMM YYYY")}
          </>
        )}
        <ArrowDropDown fontSize="small" />
      </Button>

      <Menu
        PaperProps={{
          style: {
            border: `1px solid ${theme.palette.action.disabled}`,
          },
        }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="date-dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {periodList.map((periodObj) => {
          return (
            <StyledMenuItem
              key={periodObj.url}
              value={periodObj.url}
              className={classes.customWidth}
              onClick={() => {
                setAnchorEl(null);
                history.push({
                  pathname: location.pathname,
                  search: `?${querystring.stringify({
                    ...querystring.parse(location.search.replace("?", "")),
                    period: periodObj.url,
                  })}`,
                });
              }}
            >
              {t(`navigation_bar.${periodObj.url}`)}
            </StyledMenuItem>
          );
        })}

        <StyledMenuItem
          key={"custom"}
          value={"custom"}
          className={classes.customWidth}
          onClick={() => {
            setOpenCustomDateRange(true);
            setAnchorEl(null);
          }}
        >
          {t(`navigation_bar.adjusted_period_label`)}
        </StyledMenuItem>

        <StyledMenuItem
          key={"custom"}
          value={"custom"}
          className={classes.customWidth}
          onClick={() => {
            smartDateRange();
            setAnchorEl(null);
          }}
        >
          Smart Date Range
        </StyledMenuItem>
      </Menu>
    </>
  );
}

export default DateRangeSelector;
