import { Avatar, Box, Hidden, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Business } from '@material-ui/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AddToCrmButton from './AddToCrmButton';

function CompanyHeader() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const companyDataById = useSelector((state) => state.companyDataById);

  const { companyInfo } = companyDataById[selectedCompanyId];
  const [fallbackIcon, setFallbackIcon] = useState(null);
  const errorHandler = () => {
    setFallbackIcon(<Business />);
  };

  return (
    <Box display="flex" flexDirection={matches === true ? 'row' : 'column'} justifyContent="space-between" m={2}>
      <Box display="flex" flexDirection={matches === true ? 'row' : 'column'}>
        <Box m={1} ml={0} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <Avatar
            variant={matches === true ? 'rounded' : 'circular'}
            imgProps={{
              onError: errorHandler,
            }}
            src={
              companyInfo.domain === 'gladior.com'
                ? `https://www.gladior.com/resources/cropped-icon-512x512-1-3.png`
                : `https://logo.clearbit.com/${companyInfo.domain}`
            }
          >
            {fallbackIcon}
          </Avatar>
        </Box>

        <Box m={1}>
          <Typography variant="h6" color="inherit" align={matches === true ? 'left' : 'center'}>
            {companyInfo.name}
          </Typography>

          <Typography color="textSecondary" align={matches === true ? 'left' : 'center'}>
            {companyInfo.industry}
          </Typography>
        </Box>
      </Box>
      <Hidden xsDown>
        <Box m={1} display="flex" flexDirection="row" justifyItems="center" justifyContent="center" alignItems="center">
          <AddToCrmButton />
        </Box>
      </Hidden>
    </Box>
  );
}

export default CompanyHeader;
