import dayjs from "dayjs";
import generatePeriodsList from "./generatePeriodsList";

let periodList = generatePeriodsList([
  "LAST_7_DAYS",
  "LAST_14_DAYS",
  "LAST_30_DAYS",
  "LAST_WEEK",
  "LAST_MONTH",
  "LAST_3_MONTHS",
  "LAST_12_MONTHS",
  "LAST_YEAR",
]);

function generateNewSelectedInterval(intervalUrl) {
  if (intervalUrl.indexOf("-") > 1 && intervalUrl.charAt(0) === "2") {
    const startDate = dayjs(intervalUrl.split("-")[0], "YYYYMMDD", "nl").add(
      3,
      "hour"
    );
    const endDate = dayjs(intervalUrl.split("-")[1], "YYYYMMDD", "nl").add(
      3,
      "hour"
    );
    let interval = "day";
    let diff = endDate.diff(startDate, "days");
    if (diff < 40) {
      interval = "day";
    } else if (diff > 39 && diff < 90) {
      interval = "week";
    } else {
      interval = "month";
    }

    return {
      interval: interval,
      startDate: startDate.toDate().getTime(),
      endDate: endDate.toDate().getTime(),
      url: intervalUrl,
    };
  } else {
    let returnValue;

    periodList.forEach(function (period) {
      if (period.url === intervalUrl) {
        returnValue = {
          interval: period.interval,
          startDate: period.startDate,
          endDate: period.endDate,
          url: period.url,
        };
      }
    });

    return returnValue;
  }
}

export default generateNewSelectedInterval;
