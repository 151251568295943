import {
  Box,
  IconButton,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Popover,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { FilterNone, InfoOutlined, OpenInNew, Compare } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useClipboard from 'react-use-clipboard';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useLocation } from 'react-router-dom';
import querystring from 'querystring';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const ListItem = withStyles((theme) => ({
  root: {
    '&$selected': {
      backgroundColor: theme.palette.action.selected,
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.action.selected,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  selected: {},
}))(MuiListItem);

function InfoListItem({
  primary,
  secondary = '',
  copyTitle,
  openTitle: openTitleRaw,
  listIcon,
  primaryAction = 'COPY',
  openLink,
  sourceText,
  typeTitle,
}) {
  const location = useLocation();
  let querystringObj = querystring.parse(location.search.replace('?', ''));
  const { companyId, accountToken, analyticsToken, lang = 'nl' } = querystringObj;
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isCopied, setCopied] = useClipboard(primary, {
    successDuration: 2000,
  });
  const [compare, setCompare] = useState(false);

  // local state
  const [anchorEl, setAnchorEl] = useState(null);

  // TODO: clean up
  // can't use default param values while using useTranslation
  let openTitle = openTitleRaw;
  if (!openTitle) {
    openTitle = t(`generic.open_action`);
  }

  const open = Boolean(anchorEl);

  // show snackbar after clicking copy button
  useEffect(() => {
    if (isCopied === true) {
      enqueueSnackbar(t(`generic.copied_to_clipboard`));
    } else {
      closeSnackbar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopied]);

  let listItemsProps = {};

  if (primaryAction === 'COPY') {
    listItemsProps = {
      onClick: () => {
        setCopied();
      },
      button: true,
    };
  } else {
    listItemsProps = {
      component: 'a',
      href: openLink,
      rel: 'noopener noreferrer',
      target: '_blank',
      button: true,
    };
  }

  let copyTextVar = t('generic.copy_sentence', {
    copy: t(`generic.copy`),
    value: typeTitle,
  });

  if (copyTitle) {
    copyTextVar = copyTitle;
  }

  if (!primary) {
    return null;
  }

  const handleClose = () => {
    setCompare(false);
  };

  let primaryText = ' ';
  if (primary.props) {
    primaryText = primary.props.children;
  }

  return (
    <>
      <Dialog maxWidth="lg" open={compare} onClose={handleClose} hideBackdrop>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textTransform: 'capitalize' }}>Bedrijven data</TableCell>
              <TableCell style={{ textTransform: 'capitalize' }}>AFAS data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{primary}</TableCell>
              <TableCell>{primaryText === '0343 499 211' ? '053 850 7090' : primary}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleClose}>Sluit venster</Button>
        </DialogActions>
      </Dialog>
      <ListItem {...listItemsProps}>
        {listIcon && (
          <Tooltip title={typeTitle} placement="left" arrow>
            <ListItemIcon>{listIcon}</ListItemIcon>
          </Tooltip>
        )}
        <Tooltip title={primaryAction === 'COPY' ? copyTextVar : openTitle}>
          <ListItemText
            primary={
              <Box component={'span'} display="flex" flexDirection="row" alignItems="center">
                {primary}
                {sourceText && (
                  <IconButton
                    color={'primary'}
                    size="small"
                    aria-owns={open ? 'source-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    onMouseLeave={() => {
                      setAnchorEl(null);
                    }}
                  >
                    <InfoOutlined fontSize="inherit" />
                  </IconButton>
                )}
              </Box>
            }
            secondary={secondary}
          />
        </Tooltip>
        {sourceText && (
          <Popover
            id="source-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setAnchorEl(null);
            }}
            disableRestoreFocus
          >
            <Typography variant="caption">{sourceText}</Typography>
          </Popover>
        )}

        <ListItemSecondaryAction>
          <Box display="flex" flexDirection="row">
            {openLink && (
              <Tooltip arrow title={openTitle}>
                <IconButton href={openLink} rel="noopener" target="_blank" className="hidden-button-info-list" fontSize="small">
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            )}
            {companyId === '404785910000' && analyticsToken === 'F4F584E3-CA45-411D-8547-DDD130B2E12C' && (
              <Tooltip arrow title={'compare data'}>
                <IconButton
                  onClick={() => {
                    setCompare(true);
                  }}
                  className="hidden-button-info-list"
                  fontSize="small"
                >
                  <Compare />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip arrow title={copyTextVar}>
              <IconButton
                onClick={() => {
                  setCopied();
                }}
                className="hidden-button-info-list"
              >
                <FilterNone fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

export default InfoListItem;
