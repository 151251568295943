const shouldFetchCompanyData = (analyticsDataById, companyId, timeRange) => {
  const analyticsData = analyticsDataById[companyId];
  if (!analyticsData) {
    return true;
  }

  if (analyticsData.timeRange !== timeRange) {
    return true;
  }

  if (analyticsData.isFetching) {
    return false;
  }
  return analyticsData.didInvalidate;
};

function fetchAnalyticsDataIfNeeded(analyticsDataById, companyId, timeRange) {
  if (shouldFetchCompanyData(analyticsDataById, companyId, timeRange)) {
    return true;
  } else {
    return false;
  }
}

export default fetchAnalyticsDataIfNeeded;
